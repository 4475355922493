.LateralNavigation {
    background-color: $green;
    height: 100vh;
    width: $lateral-navigation-width;
    list-style-type: none;

    position: fixed;
    left: 0;
    top: 0;

    font-size: $lateral-navigation-font-size;
    color: $textwhite;

    // MOBILE
    &__Toggle {
        background-color: transparent;
        border: none;
        outline: none;

        & > svg {
            height: 45px;
            width: 45px;
        }
    }

    // MOBILE
    &__List {
        position: absolute;
        top: 100%;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: $green;
        z-index: -1;
        transform: translateY(-100%);
        transition: transform 0.25s ease-in;
    }

    // MOBILE
    &__List--Open {
        transform: translateY(0);
    }

    // MOBILE
    &__Link--Mobile {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $white;
        width: 250px;

        &.active {
            color: $orange;
        }
    }

    &__Item {

        text-align: center;
        padding: 0;
        margin: 50px 0;

        &--Profile {
            text-align: center;
            width: 50px;
            height: 50px;
            color: $green;
            margin-left: auto;
            margin-right: auto;

            background-color: white;
            border-radius: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__Letter{
        display: flex;
        justify-content: center;
        align-self: center;
        font-size: 3rem;
    }

    &__Link {
        text-decoration: none;
        color: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        fill: white;

        &.active {
            color: $orange;
        }

        &:hover {
            cursor: pointer;
            color: $orange;
            fill: $orange;
        }
    }


    &__Icon {
        height: 35px;
        width: auto;
        display: block;
    }

    &__Label {
        display: block;
        font-size: 14px;
        margin-top: 5px;
        text-transform: capitalize;
        font-weight: bold;
    }

    &__Logout {
        position: absolute;
        bottom: 50px;
        right: 50%;
        transform: translateX(50%);
        border: none;
        background-color: transparent;
        fill: white;
        color: white;

        &:hover {
            cursor: pointer;
            fill: $orange;
            color: $orange;
        }
    }
}

// MOBILE
.LateralNavigation--Mobile {
    position: fixed;
    top: 0;
    left: 0;
    height: $top-navigation-height;
    width: 100%;
    background-color: $green;
    z-index: 2;
    padding: 0 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .LateralNavigation__Item {
        display: flex;
        justify-content: center;

        &--Profile {
            margin-left: initial;
            margin-right: initial;
            height: 60px;
            width: 60px;

            & > p {
                font-size: 40px;
            }
        }
    }

    .LateralNavigation__Icon {
        height: 55px;
    }

    .LateralNavigation__Label {
        font-size: 30px;
        margin-left: 50px;
    }

    .LateralNavigation__Logout {
        position: relative;
        display: flex;
        align-items: center;
        right: initial;
        bottom: initial;
        transform: none;
        width: 250px;

        .LateralNavigation__Label {
            font-size: 18px;
        }

        .LateralNavigation__Icon {
            height: 40px;
        }
    }
}

@include mq($from: iphone5, $until: desktop) {

    .LateralNavigation {
        display: none;
    }

}

@include mq($from: desktop) {

    .LateralNavigation--Mobile {
        display: none;
    }

}