*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Jost', sans-serif;
}

html {
    //this define what 1rem is
    // font-size: 35%;
    font-family: 'Jost', sans-serif;
    font-size: 16px;
}

.MainContents {
    margin-left: 0;
    margin-top: $top-navigation-height;
}


@include mq($from: tablet) {

}

@include mq($from: desktop) {

    .MainContents {
        margin-left: $lateral-navigation-width;
        margin-top: 0;
    }
}