.AnalyticsFilters {
    display: none;

    &__Wrapper {
        height: $top-navigation-height;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
    }

    &__List {
        list-style-type: none;
        display: flex;
        align-items: baseline;
    }
    
    &__Option {
        font-size: 20px;
        padding: 0 25px;
    }

    &__Option--first {
        padding-left: 0;
    }
    
    &__Option--last {
        padding-right: 0;
        padding-left: 0;
    }

    &__Link {
        border: none;
        background-color: transparent;
        color: $green;
        font-size: 2rem;
        transition: color 0.15s ease-out;
        outline: $green;
    
        &:hover {
            color: $orange;
            cursor: pointer;
        }
    
    }

    &__Link--Active {
        color: $orange;
    }

    &__Custom {
        border: none;
        background-color: transparent;
        color: $green;
        font-size: 2rem;

        &:hover {
            cursor: pointer;
            color: $orange;
        }

        &:focus {
            outline: none;
        }
    }
    
    &__Custom--Active {
        color: $orange;
    }
}


.AnalyticsFilters--Mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    height: $bottom-filters-height;
    background-color: $green;

    .AnalyticsFilters {

        &__Wrapper--Mobile {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $green;
            padding: 0 15px;
            height: 100%;
        }

        &__Domain, &__Date {
            background: transparent;
            border: none;
        }

        &__Domain {
            color: $white;
            font-size: 18px;
        }
        
        &__Date {
            color: $orange;
            font-size: 26px;
            font-weight: bold;
        }

        &__BottomSheet {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100vh - #{$bottom-filters-height});
            background-color: $green;
            z-index: -1;
            transition: transform 0.25s ease-in;
            overflow-y: hidden;
            padding-bottom: 10px;

            &--Open {
                transform: translateY(-100%);
            }
        }

        &__CloseBottomSheet {
            position: absolute;
            right: 10px;
            top: 10px;
            border: none;
            background: transparent;

            & > svg {
                transform: rotate(90deg);
                height: 40px;
                width: 40px;
            }
        }

        &__BottomSheetList {
            margin-top: 50px;
            padding-bottom: 50px;
            overflow: scroll;
            position: absolute;
            height: 100%;
            width: 100%;
        }

        &__BottomSheetWebsite {
            color: $white;
            font-size: 20px;
            margin: 15px 0;
            padding-left: 10%;

            &--Active {
                color: $orange;
                padding-left: 10px;
            }
        }

        &__BottomSheetDates {
            margin-top: 50px;
        }

        &__DateOption {
            color: $white;
            margin: 15px 0;
            padding-left: 10%;

            & > button {
                font-size: 26px;
                color: inherit;
                border: none;
                background-color: transparent;

                &.Active {
                    color: $orange;
                }
            }
        }

        &__Form {
            margin-top: 50px;
            padding-left: 10%;
        }

        &__Custom {
            color: $white;
            font-size: 26px;
            display: block;
            margin-bottom: 15px;
        }

        &__Range {
            display: inline-block;
            max-width: 50%;
        }

    }

}

.DatePicker {
    font-size: 20px;
    background-color: transparent;
    border: none;
    color: $white;

    &::placeholder {
        color: $white;
    }
}

.react-datepicker {
    transform: scale(1.8) translate(-35px, 25px);
}

@include mq($from: desktop) {
    .react-datepicker {
        transform: scale(1.5) translate(-35px, 25px);
    }

    .DatePicker {
        font-size: 2rem;
        border: none;
        border-bottom: 1px solid $green;
        max-width: 100px;
        outline: none;
        text-align: center;
        color: $green;
    
        &::placeholder {
            color: $green;
        }
    
        &:hover {
            cursor: pointer;
        }
    }

    .AnalyticsFilters--Mobile {
        display: none;
    }

    .AnalyticsFilters {
        display: block;
    }
}