.Login {

    &__Wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    &__Hero {
        height: 40vh;
        background-color: $green;
        color: $textwhite;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;

        &Title {
            font-size: 35px;
            margin-bottom: 20px;
        }

        &PayOff {
            font-size: 22px;
        }
    }

    &__Form {
        height: 60vh;
        color: $green;

        &Title {
            font-size: 26px;
            margin-bottom: 20px;
        }
    }

    &__FormLabel {
        color: $green;
        font-size: 18px;
    }
    
    &__FormButton {
        border: none;
        height: 40px;
        background-color: $green;
        color: white;
        font-size: 20px;
        width: 100%;
        text-align: center;
        margin-top: 15px;
    }
}


@include mq($from: mobile, $until: desktop) {

    .Login {

        &__Wrapper {
            width: 90%;
            margin: 0 auto;
        }
        
    }

}

@include mq($from: desktop) {
    
    .Login {

        &__Wrapper {
            width: 80%;
            margin: 0 auto;
        }

        &__Hero {
            height: 100vh;
    
            &Title {
                font-size: 45px;
            }
    
            &PayOff {
                font-size: 28px;
            }
        }
        
    
        &__Form {
            height: 100vh;
        }
    }

}
