.Websites {
    position: relative;
    overflow-x: hidden;
    min-height: 100vh;

    &__Hero {
        margin-top: 15px;
    }

    &__AddButtonContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__AddButton{
        font-size: 1.5rem;
        height: 50px;
        width: 120px;
        border-radius: 8px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        transform: perspective(1px) translateZ(0);
        transition-duration: 0.3s;
        transition-property: transform, background-color;
    
        color: $textwhite;
        background-color: $green;
    
        &:hover {
            background-color: $orange !important;
            transform: scale(1.1);
        }
    }

    &__NoDomainMessage{
        width: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        align-items: center;

        font-size: 4rem;
        color: $orange;
    }

    &__Contents {
        filter: blur(0px);
        transition: filter .6s;
    }

    &__Contents--Open {
        filter: blur(5px);
    }

    &__Overlay {
        width: calc(100% - 70px);
        height: 100vh;
        position: absolute;
        left: 70px;
        top: 0;
        z-index: 1;
    }

    .AddPanel {
        background-color: $green;
        width: 400px;
        height: 100%;
        padding-left: 20px;
        position: fixed;
        right: 0;
        top: 0;
        transition: transform .6s;
        transform: translateX(0);
        z-index: 2;
    
        &__animation{
            transform: translateX(100%);
        }
    
        &__BackButton{
    
            margin-left: auto;
            margin-right: 10px;
            margin-bottom: 30%;
    
            svg{
                fill: $textwhite !important;
            }
        }
    
        &__Title{
            color: $textwhite;
            font-size: 3.2rem;
            margin-bottom: 60px;
        }
    
        &__InputField{
            width: 90%;
            margin-top: 10px;
            margin-right: 10px;
    
            &__Label{
                font-size: 2rem;
                color: $textwhite;
    
                & + span{
                    color: $textwhite;
                } 
            }
        }
    
        &__ErrorMessage{
            color: black;
            font-size: 1.9rem;
        }
    
        &__AddNewButtonSection{
            display: flex;
            align-items: center;
            margin-top: 3rem;
            margin-bottom: 2.5rem;
        }

        &__AddNewButton{
            margin-right: 1rem;
            color: $green;
            background-color: white;
            height: 50px;
            font-size: 1.8rem; 
            transition-duration: 0.3s;
            
            &:hover {
                background-color: $orange !important;
            }
        }

        &__TickIcon{
            height: 30px;
            width: 30px;
            fill: $textwhite !important;
            animation-duration: 2s;
            animation-name: scale-up;
        }
    }
}

.WebsitesContainer{
    position: relative;
}

.WebsitesTable{
    margin-top: 4rem;

    &__Header{
        background-color: white;
        border: $table-border;
        padding: 0;
        
        &--Cell{
            padding: $table-cell-padding;
        }

        &--Button{
            color: $green;
            padding: 0;
            width: 100%;
        }
    }

    &__VirtualBody{
        div{
            overflow: hidden !important;
        }    
    }

    &__Row{
        background-color: white;
        border-left: $table-border;
        border-bottom: $table-border;
        border-right: $table-border;

        &--Cell{
            padding: $table-cell-padding;
            
            span{
                color: $green;
            }
        }
    }

    &--IconButton{
        svg{
            fill: $green !important;
        }
    }
}

.WebsitesTable--MobileTable{

    margin-top: 2rem;

    &__Row:first-child{
        border-top: $table-border;
    }

    &__Row{
        background-color: white;
        border-left: $table-border;
        border-bottom: $table-border;
        border-right: $table-border;

        &--GreenBackground{
            background-color: #DAEFE6;
        }
        
        &--Cell{
            padding: $table-cell-padding;
            
            span{
                color: $green;
            }
        }
    }

    &--IconButton{
        svg{
            fill: $green !important;
        }
    }
}

.WebsitesFirstRow{
    padding: 4rem 0 0 0;
}

.WebsitesTitle{
    font-size: 8rem;
    color: $green;
    font-weight: lighter;
    display: inline-block;
    margin-right: 15px;
    line-height: 1;
}

.WebsitesSubTitle{
    font-size: 4rem;
    color: $green;
    font-weight: lighter;
    display: inline-block;
    // margin-left: 2rem;
}


.TableNavigationButton{
    border: none;
    background-color: transparent;
    color: $green;
    margin: 0;
    padding: 0;
    text-decoration: underline;
    font-size: 1.5rem;
    
    &:hover {
        cursor: pointer;
    }

    &__Disabled{
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.65;
    }
}

.PageNumber{
    border: none;
    color: $textwhite;
    font-size: 1.8rem; 
    background-color: $green;
    height: 4.5rem;
    width: 4rem;
    margin: 0 3px;
    border-radius: 5px;
    transition-duration: 0.3s;
        
    &:hover {
        background-color: $orange !important;
    }

    &--Selected{
        background-color: $orange !important;
    }
}

.CopyBanner{
    position: absolute;
    bottom: 3rem;
    left: 4rem;
    padding: 5px 10px;
    border-radius: 3px;
    background-color: $green;

    font-size: 2rem;
    color: $textwhite;
    visibility: hidden;

    animation-duration: 2s;
    
    &__Animation{
        animation-name: fade-out;
    }
}

@keyframes fade-out {
    from {
        visibility: visible;
        opacity: 1;
    }
  
    to {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes scale-up{
    0%{
        transform: scale(0);
    }

    50%{
        transform: scale(1);
    }

    100%{
        transform: scale(1);
    }
}

.DeleteButton{
    font-size: 2rem;
    color: $textwhite;
    height: 5rem;
    width: 8rem;
    display: inline-flex;
    justify-content: center;

    &--No{
        background-color: $green !important;
        margin-right: 1.5rem;
    }
    
    &--Yes{
        background-color: $red !important;
        margin-left: 1.5rem;
    }
}

@include mq($from: iphone5, $until: tablet) {

    .Websites__AddButton{
        font-size: 8rem;
        height: 50px;
        width: 50px;
        border-radius: 50%;

        &--DesktopText{
            display: none;
        }
    }

    .WebsitesTable{
        margin-top: 0;

        &--DesktopTable{
            display: none;
        }
    }

    .TableNavigationButton{
        font-size: 2.5rem;
    }

    .PageNumber{
        height: 7.31rem;
        width: 6.5rem;
        font-size: 2.5rem;
    }
}

@include mq($from: tablet, $until: desktop) {

    .Websites__AddButton{
        font-size: 1.9rem;

        &--MobileText{
            display: none;
        }
    }

    .PageNumber{
        height: 6.75rem;
        width: 6rem;
        font-size: 2.3rem;
    }

    .WebsitesTable--MobileTable{
        display: none;
    }

    
}

@include mq($from: desktop){
    
    .WebsitesTable--MobileTable{
        display: none;
    }

    .Websites__AddButton{
        &--MobileText{
            display: none;
        }
    }
}