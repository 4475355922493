//Lateral Navigation Width
$lateral-navigation-width: 80px;

//Top Navigation height
$top-navigation-height: 50px;

// mobile filters
$bottom-filters-height: 50px;

//Table cell padding
$table-cell-padding: 0 12px;

//Table row height (sum of height + border)
$table-row-height: 48px;


$grid-gutter-width: 30px !default;

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 992px
);

// Small screen / tablet
$container-tablet: 720px + $grid-gutter-width !default;

//** For `$screen-sm-min` and up.
$container-sm: $container-tablet !default;

// Medium screen / desktop
$container-desktop: 940px + $grid-gutter-width !default;

//** For `$screen-md-min` and up.
$container-md: $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop: 1140px + $grid-gutter-width !default;

//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop !default;


$container-max-widths: (
  sm: $container-sm,
  md: $container-md,
  lg: $container-lg,
  xl: $container-lg
) !default;

