.Loader {
    font-size: 4rem;
    text-align: center;
}

.GlobalLoader {
    font-size: 4rem;
    position: fixed;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}